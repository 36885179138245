import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import jwtDecode from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT } from 'shared-ui/store/actions';
import accountReducer from 'shared-ui/store/accountReducer';

// project imports
import Loader from 'shared-ui/ui-component/Loader';

import { axiosAuthService, axiosService } from 'shared-ui/utils/axios';

// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const verifyToken = (serviceToken) => {
    if (!serviceToken) {
        return false;
    }
    const decoded = jwtDecode(serviceToken);
    return decoded.exp > Date.now() / 1000;
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axiosService.defaults.headers.common['Authorization'] = serviceToken;
    } else {
        localStorage.removeItem('serviceToken');
        axiosService.defaults.headers.common['Authorization'] = null;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);

    useEffect(() => {
        const init = async () => {
            try {
                const serviceToken = window.localStorage.getItem('serviceToken');
                if (serviceToken && verifyToken(serviceToken)) {
                    setSession(serviceToken);
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true
                        }
                    });
                } else {
                    console.log('logout');
                    dispatch({
                        type: LOGOUT
                    });
                }
            } catch (err) {
                console.error(err);
                dispatch({
                    type: LOGOUT
                });
            }
        };
        init();
    }, []);

    const login = async (email, password) => {
        const response = await axiosAuthService.post('/login', { email, password });
        if (response) {
            const { serviceToken, user } = response.data;
            setSession(serviceToken);
            window.localStorage.setItem('user', JSON.stringify(response.data.user));
            dispatch({
                type: LOGIN,
                payload: {
                    isLoggedIn: true,
                    user
                }
            });
        }
    };

    const register = async (email, password, firstName, lastName) => {
        // todo: this flow need to be recode as it not verified
        const response = await axiosAuthService.post('/register', {
            email,
            password,
            firstName,
            lastName
        });
        let user = response.data;
        window.localStorage.setItem('user', JSON.stringify(user));
    };

    const logout = () => {
        setSession(null);
        dispatch({ type: LOGOUT });
    };

    const resetPassword = async (email) => {
        console.log(email);
    };

    const updateProfile = () => {
        console.log('update profile');
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider value={{ ...state, login, logout, register, resetPassword, updateProfile }}>{children}</JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
