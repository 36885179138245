import axios from 'axios';

const baseURL = `${process.env.REACT_APP_API_BASE_URL || 'http://localhost:7002/chat'}`;

// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: baseURL,
});

// Add a request interceptor to include the token in headers
axiosInstance.interceptors.request.use(
    config => {
        const token = localStorage.getItem('serviceToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

const logError = (error) => {
    if (error.response) {
        console.log('Server Response Error:', error.response.data);
        console.log('Response Status:', error.response.status);
        console.log('Response Headers:', error.response.headers);
    } else if (error.request) {
        console.log('Request Error:', error.request);
    } else {
        console.log('General Error:', error.message);
    }
};

const appService = {
    // Method to get the list of available models
    async getModels() {
        try {
            const response = await axiosInstance.get('/models');
            return response.data.models;
        } catch (error) {
            logError(error);
            throw error;
        }
    },

    // Method to send a prompt to ChatGPT and get a response
    async sendPrompt(prompt, componentName, componentCode) {
        try {
            const response = await axiosInstance.post('/chat', { prompt, componentName, componentCode });
            return response.data.response;
        } catch (error) {
            logError(error);
            throw error;
        }
    },

    // Method to get data from the protected route
    async getData() {
        try {
            const response = await axiosInstance.get('/data');
            return response.data;
        } catch (error) {
            logError(error);
            throw error;
        }
    }
};

export default appService;