// routing
import Routes from 'routes';

// project imports
import Locales from 'shared-ui/ui-component/Locales';
import NavigationScroll from 'shared-ui/layout/NavigationScroll';
// import RTLLayout from 'ui-component/RTLLayout';

import ThemeCustomization from 'shared-ui/themes';

// auth provider
import { JWTProvider as AuthProvider } from 'shared-ui/contexts/JWTContext';

// ==============================|| APP ||============================== //

const App = () => (
    <ThemeCustomization>
        <Locales>
            <NavigationScroll>
                <AuthProvider>
                    <>
                        <Routes />
                    </>
                </AuthProvider>
            </NavigationScroll>
        </Locales>
    </ThemeCustomization>
);

export default App;
