import React, { useState, useEffect, useRef } from 'react';
import * as Babel from '@babel/standalone';
import {
    AppBar,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Alert,
    AlertTitle,
    Avatar,
    Badge,
    BottomNavigation,
    BottomNavigationAction,
    Breadcrumbs,
    Button,
    ButtonGroup,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Checkbox,
    Chip,
    CircularProgress,
    Collapse,
    Container,
    CssBaseline,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Drawer,
    Fab,
    Fade,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputBase,
    InputLabel,
    LinearProgress,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    Menu,
    MenuItem,
    Modal,
    Pagination,
    PaginationItem,
    Paper,
    Popover,
    Popper,
    Radio,
    RadioGroup,
    Rating,
    Select,
    Skeleton,
    Slide,
    Slider,
    Snackbar,
    SnackbarContent,
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
    Stack,
    Step,
    StepButton,
    StepConnector,
    StepContent,
    StepIcon,
    StepLabel,
    Stepper,
    SvgIcon,
    SwipeableDrawer,
    Switch,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Tabs,
    TextField,
    TextareaAutosize,
    ToggleButton,
    ToggleButtonGroup,
    Toolbar,
    Tooltip,
    Typography,
    Zoom
} from '@mui/material';
import axios from 'axios';
import { isEmpty, isNull, isNumber } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useParams, useNavigate } from 'react-router-dom';
import mongoService from 'service/mongoService';

const DynamicLoader = ({componentCode, collection, rowId, preview}) => {
    const [Component, setComponent] = useState(null);

    useEffect(() => {
        if (componentCode) {
            try {
                // Transform the JSX code to JavaScript using Babel
                const transformedCode = Babel.transform(componentCode, {
                    presets: ['react']
                }).code;

                // eslint-disable-next-line no-new-func
                const componentFunction = new Function('dependencies', `
                    with (dependencies) {
                        ${transformedCode}
                        return DynamicComponent;
                    }
                `);
                const dependencies = {
                    React,
                    useState,
                    useEffect,
                    useRef,
                    axios,
                    isEmpty,
                    isNull,
                    isNumber,
                    uuidv4,
                    useParams,
                    useNavigate,
                    mongoService,
                    AppBar,
                    Accordion,
                    AccordionSummary,
                    AccordionDetails,
                    Alert,
                    AlertTitle,
                    Avatar,
                    Badge,
                    BottomNavigation,
                    BottomNavigationAction,
                    Breadcrumbs,
                    Button,
                    ButtonGroup,
                    Card,
                    CardActionArea,
                    CardActions,
                    CardContent,
                    CardHeader,
                    CardMedia,
                    Checkbox,
                    Chip,
                    CircularProgress,
                    Collapse,
                    Container,
                    CssBaseline,
                    Dialog,
                    DialogActions,
                    DialogContent,
                    DialogContentText,
                    DialogTitle,
                    Divider,
                    Drawer,
                    Fab,
                    Fade,
                    FormControl,
                    FormControlLabel,
                    FormGroup,
                    FormHelperText,
                    FormLabel,
                    Grid,
                    IconButton,
                    Input,
                    InputAdornment,
                    InputBase,
                    InputLabel,
                    LinearProgress,
                    Link,
                    List,
                    ListItem,
                    ListItemAvatar,
                    ListItemIcon,
                    ListItemSecondaryAction,
                    ListItemText,
                    ListSubheader,
                    Menu,
                    MenuItem,
                    Modal,
                    Pagination,
                    PaginationItem,
                    Paper,
                    Popover,
                    Popper,
                    Radio,
                    RadioGroup,
                    Rating,
                    Select,
                    Skeleton,
                    Slide,
                    Slider,
                    Snackbar,
                    SnackbarContent,
                    SpeedDial,
                    SpeedDialAction,
                    SpeedDialIcon,
                    Stack,
                    Step,
                    StepButton,
                    StepConnector,
                    StepContent,
                    StepIcon,
                    StepLabel,
                    Stepper,
                    SvgIcon,
                    SwipeableDrawer,
                    Switch,
                    Tab,
                    Table,
                    TableBody,
                    TableCell,
                    TableContainer,
                    TableFooter,
                    TableHead,
                    TablePagination,
                    TableRow,
                    TableSortLabel,
                    Tabs,
                    TextField,
                    TextareaAutosize,
                    ToggleButton,
                    ToggleButtonGroup,
                    Toolbar,
                    Tooltip,
                    Typography,
                    Zoom
                };
                const component = componentFunction(dependencies);
                setComponent(() => component);
            } catch (err) {
                console.error('Error evaluating the component:', err);
            }
        }
    }, [componentCode]);

    if (!Component) {
        return <div>Loading component...</div>;
    }

    return <Component collection={collection} rowId={rowId} preview={preview} />;
};

export default DynamicLoader;
