import axios from "axios";

// Base URLs from environment variables
const apiUrl = `${process.env.REACT_APP_API_BASE_URL}`;
const appUIDashUrl = `${process.env.REACT_APP_UI_DASHBOARD_URL}`;
const baseURL = `${process.env.REACT_APP_API_BASE_URL || 'http://localhost:7002/chat'}`;

const fileUploadURL = `${process.env.REACT_APP_FILE_UPLOAD_URL}`;

// Create an Axios instance for API calls
const axiosInstance = axios.create({
  baseURL: baseURL,
});

// Create an Axios instance for dashboard calls
const dashAxiosInstance = axios.create({
  baseURL: appUIDashUrl,
});

const uploadAxiosInstance = axios.create({
  baseURL: fileUploadURL,
});


// Add a request interceptor for the API instance to include the token in headers
axiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('serviceToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      console.log(token, "service token");
    } else {
      console.log('Service token not found in localStorage');
    }
    return config;
  },
  error => Promise.reject(error)
);

// Add a request interceptor for the dashboard instance
dashAxiosInstance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('serviceToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

// API service
const api = {
  fetchData: async (page, limit, query, sessionId, uuid, endpoint,appId) => {
    try {
      const response = await axiosInstance.post(`${apiUrl}${endpoint}`, { query, sessionId, uuid,appId }, {
        params: { page, limit },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message || "Request failed");
    }
  },

  fetchFiles: async (query, uuid) => {
    try {
      const response = await axiosInstance.post(`${apiUrl}run_files`, { query, uuid }, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.message || "Request failed");
    }
  },

  // uploadFile: async (file, appID, sessionID, authToken) => {
  //   try {
  //     const formData = new FormData();
  //     formData.append('file', file);
  //     const response = await axiosInstance.post(`${apiUrl}upload`, formData, {
  //       headers: {
  //         "Content-Type": "multipart/form-data",
  //       },
  //     });
  //     return response.data;
  //   } catch (error) {
  //     throw new Error(error.message || "File upload failed");
  //   }
  // },

  uploadFile: async (file, appID, sessionID) => {
    const token = localStorage.getItem('serviceToken');
    try {
      const formData = new FormData();
      formData.append('file', file);
  
      // Correctly format the URL without extra characters
      const uploadURL = `${fileUploadURL}${appID}/uploadfiles/`;
      console.log("Upload URL:", uploadURL);

  
      const response = await axiosInstance.post(uploadURL, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "Accept": "application/json",             // Accept header
          "session-id": sessionID,                  // Session ID header
          "Authorization": `Bearer ${token}`, // Set the Bearer token
        },
      });
  
      return response.data;
    } catch (error) {
      throw new Error(error.message || "File upload failed");
    }
  },
  
  getData: async () => {
    try {
      const response = await dashAxiosInstance.get(``); // Use `/` for clarity
      return response.data;
    } catch (error) {
      throw new Error(error.message || "GET request failed");
    }
  },

  detRefineData: async (query, sessionId,appId) => {
    try {
      const response = await axiosInstance.post(`${apiUrl}refine_query_module`, { query, sessionId,appId }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.message || "Refinded Question Request failed");
    }
  },
  historyData: async (appId) => {
    try {
      const response = await axiosInstance.get(`${apiUrl}user/sessions?appId=${appId}`);
      return response.data;
    } catch (error) {
      throw new Error(error.message || "Refinded Question Request failed");
    }
  },

  fetchData_History: async (page, sql, limit, uuid, appId) => {
    try {
      const response = await axiosInstance.post(
        `${apiUrl}run_sql`, // API URL for executing SQL queries
        { appId, uuid, sql }, // POST request payload with necessary data
        {
          params: { // Query parameters to handle pagination
            page, // Current page number
            limit // Rows per page (limit for pagination)
          }
        }
      );
      
      // Ensure that the response has data and return it
      return response.data; 
    } catch (error) {
      // Handle errors properly by throwing meaningful messages
      throw new Error(error.response?.data?.message || "Request failed during history data fetch");
    }
  }
  

};

// curl --location 'localhost:7002/eda/chat/run_sql?page=1&limit=10' \
// --header 'Content-Type: application/json' \
// --header 'Authorization: ••••••' \
// --data '{
//   "appId": "6708bb9d7f0f5809f6c36883",
//   "uuid": "20a11fac-d3bc-4dca-8d22-d4d4acdc21d3",
//   "sql":"SELECT start_date, std1_casting_speed \nFROM data \nWHERE start_date >= '\''2022-08-01'\'' AND start_date < '\''2022-08-08'\'' \nORDER BY start_date;"
// }'


export default api;
