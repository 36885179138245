import React, { useEffect, useState } from "react";
import { Typography, Grid, CircularProgress,IconButton } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import api from "service/api";
import { Box } from "@mui/system";
import TimelineIcon from "@mui/icons-material/Timeline";
import HistoryIcon from '@mui/icons-material/History';
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import SubCard from "shared-ui/ui-component/cards/SubCard";
// import SubCard from "shared-ui/ui-component/cards/SubCard";

const HistorySection = ({
  appId,
  onHistoryPromptsUpdate,
  onSessionIdUpdate,
  onHistoryQueries,
  OnQueryIdUpdate,
  OnSqlUpdate,
  
}) => {
  const theme = useTheme();
  const location = useLocation();
  const { title, endpoint } = location.state || {};

  const [entriesList, setEntriesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [historyPrompts, setHistoryprompts] = useState([]);

  const [activeQueryId, setActiveQueryId] = useState(null); // Track active query
  const [expandedSession, setExpandedSession] = useState(null); // Track expanded session


  // Fetch history data
  const fetchHistoryData = async () => {
    setLoading(true);
    try {
      const response = await api.historyData(appId);
      const sessionsWithQueries = response.map((session) => ({
        session_id: session.session_id,
        time: session.time,
        queries: session.entries.map((entry) => ({
          query: entry.query,
          query_id: entry.query_id,
          sql: entry.sql,
          time: entry.time
        }))
      }));

      setEntriesList(sessionsWithQueries);
    } catch (error) {
      console.error("Error fetching history data:", error);
    } finally {
      setLoading(false);
    }
  };

  const formatTime = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    const time = date.toISOString().split("T")[1].split(".")[0];

    return `${day} ${month} ${year} - ${time}`;
  };

  const handleQueryClick = async (sql, query, query_id, session_id) => {
    setLoading(true);
    setActiveQueryId(query_id); // Set the active query ID

    try {
      const response = await api.fetchData_History(
        1, // Page number (starting from 1)
        sql, // SQL query from the selected card
        10, // Limit of entries per page
        uuidv4(), // Generate a new UUID for this request
        appId // Application ID
      );
console.log(response,'prompts select response');

      if (response && response.response) {
        setHistoryprompts(response);
        onHistoryPromptsUpdate(response);
        onHistoryQueries(query);
        OnQueryIdUpdate(query_id);
        onSessionIdUpdate(session_id);
        OnSqlUpdate(sql);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

   // Toggle session expansion
   const toggleSessionExpansion = (session_id) => {
    setExpandedSession(expandedSession === session_id ? null : session_id);
  };


  useEffect(() => {
    fetchHistoryData();
  }, [appId]);

  return (
    <Grid container mt={3} gap={1} mx="auto" fullWidth alignItems="start">
    {loading ? (
      <Grid container justifyContent="center" mt={2}>
        <CircularProgress />
      </Grid>
    ) : (
      <>
        {entriesList.length > 0 ? (
          entriesList.map((entry, sessionIndex) => (
            <Box
              key={sessionIndex}
              sx={{
                background: theme.palette.background.default,
                borderRadius: 2.3,
                minWidth: "230px",
                width: "100%",
                textAlign: "left",
                margin: ".3rem",
                border: "1px solid",
                borderColor: theme.palette.background.border,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: '1rem',
                flexDirection: 'column', // Adjusted to allow flex in column
              }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" sx={{
                cursor:"pointer",
                
                }} onClick={() => toggleSessionExpansion(entry.session_id)} >
              <Box display='flex' alignItems='center'>
              <HistoryIcon sx={{ color: theme.palette.primary.main, mr: 2 }} />
             <Box mb={1}>
             <Typography variant="subtitle1" sx={{ mb: 0 }}>
                  {/* Session ID: {entry.session_id} */}
                  Session Date & Time: 
                </Typography>
                <Typography>{formatTime(entry.time)}</Typography>
             </Box>
              </Box>
                <IconButton onClick={() => toggleSessionExpansion(entry.session_id)}>
                  {expandedSession === entry.session_id ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              </Box>

              {/* Render queries if the session is expanded */}
              {expandedSession === entry.session_id && (
                <Box display="flex" flexDirection="column" p={2} sx={{background: theme.palette.background.bg, width:'100%'}}>
                  {entry.queries.map((query, queryIndex) => (
                    <SubCard
                      key={query.query_id}
                      display="flex"
                      alignItems="center"
                     
                      onClick={() =>
                        handleQueryClick(query.sql, query.query, query.query_id, entry.session_id)
                      }
                      sx={{
                        width:'100%',
                        cursor: "pointer",
                        mb: 1,
                        border: '1px solid',
                        borderColor:
                          activeQueryId === query.query_id
                            ? theme.palette.primary.main // Change color if active
                            : theme.palette.background.paper, // Default background color
                        
                        background:
                        activeQueryId === query.query_id
                          ? theme.palette.secondary.light // Change color if active
                          : theme.palette.background.paper, // Default background color
                      "&:hover": {
                        backgroundColor: theme.palette.action.hover,
                      },

                      }}
                    >
                      <TimelineIcon sx={{ color: theme.palette.secondary.main, mr: 2 }} />
                      <Typography variant="chatText">
                        {formatTime(query.time)} -- {query.query}
                      </Typography>
                    </SubCard>
                  ))}
                </Box>
              )}
            </Box>
          ))
        ) : (
          <Typography variant="body2" color="textSecondary">
            No history data available.
          </Typography>
        )}
      </>
    )}
  </Grid>
  );
};

export default HistorySection;
