/**
 * axios setup to use mock service
 */

import axios from 'axios';

let baseURL =  `${process.env.REACT_APP_AUTH_BASE_URL}`;


const getIpBaseAddress = () => {
    // Get the current URL from the browser's address bar
    const currentUrl = window.location.href;

    // Extract the protocol and hostname (IP address or domain) from the URL
    const url = new URL(currentUrl);
    const protocol = url.protocol;
    const ipAddress = url.hostname;

    return `${protocol}//${ipAddress}`;
};

console.log(getIpBaseAddress());
const baseIp = getIpBaseAddress();

// let baseURL = `${baseIp}:8001`;

const axiosAuthService = axios.create({ baseURL });

const axiosService = axios.create();

export { axiosAuthService, axiosService, baseIp };
