import config from 'shared-ui/config';
import { makeObservable, observable, action } from 'mobx';

class UIStore {
    config = {
        layout: config.layout,
        drawerType: config.drawerType,
        fontFamily: config.fontFamily,
        borderRadius: config.borderRadius,
        outlinedFilled: config.outlinedFilled,
        navType: config.navType,
        presetColor: config.presetColor,
        locale: config.locale,
        rtlLayout: config.rtlLayout,
        container: config.container,
        drawerOpen: config.drawerOpen,
        selectedItem: config.selectedItem,
        selectedID: config.selectedID,
        menu: config.menu
    };

    constructor() {
        makeObservable(this, {
            config: observable,
            updateConfig: action
        });
    }

    updateConfig(key, value) {
        this.config[key] = value;
    }
}

const uiStore = new UIStore();
export default uiStore;
