import React, { useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import { gridSpacing } from 'shared-ui/store/constant';
import FormEditor from './formEditor';

const FormBuilder = () => {
    const { collection } = useParams();

    React.useEffect(() => {
        console.log('Schema parameter:', collection);
      }, [collection]);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const objectId = searchParams.get('objectId');

    return (
        <>
            <Grid container>
                <Grid item xs={12} style={{ width: '60%', paddingRight: '16px' }}>
                    <Grid container spacing={gridSpacing}>
                        <Grid item xs={12}>
                            <FormEditor />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default FormBuilder;
