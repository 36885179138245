import React, { useEffect, useState, useRef } from 'react';
import { Button, Grid, TextField, Box } from '@mui/material';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import mongoService from 'service/mongoService';
import appService from 'service/appService';
import DynamicLoader from '../../loaders/DynamicFormsLoader';
import io from 'socket.io-client';
import { useParams } from 'react-router-dom';

const CHUNK_SIZE = 1024 * 1024; // 1MB

const FormEditor = () => {
    const collection = "_schema"
    const { rowId } = useParams(); 
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formTouched, setFormTouched] = useState(false);
    const [name, setName] = useState('');
    const [prompt, setPrompt] = useState('');
    const [jsxCode, setJsxCode] = useState('');
    const [componentCode, setComponentCode] = useState('');
    const [errors, setErrors] = useState({});
    const [componentName, setComponentName] = useState('');
    const [showPreview, setShowPreview] = useState(false);
    const [shouldRenderPreview, setShouldRenderPreview] = useState(false);
    const hasRenderedPreview = useRef(false);
    const socketRef = useRef(null);

    const baseURL = `${process.env.REACT_APP_API_BASE_URL || 'http://localhost:7002'}`;

    useEffect(() => {
        const fetchData = async () => {
            if (collection && rowId) {
                try {
                    const data = await mongoService.getDocument(collection, rowId);
                    if (!isEmpty(data)) {
                        setName(data.name);
                        setPrompt(data.prompt);
                        setJsxCode(data.jsxCode);
                    }
                } catch (error) {
                    console.error(error);
                }
            }
        };
        fetchData();
    }, [collection, rowId]);

    const validate = (fieldValues = {}) => {
        let tempErrors = { ...errors };

        if ('name' in fieldValues)
            tempErrors.name = fieldValues.name ? "" : "Name is required";
        if ('prompt' in fieldValues)
            tempErrors.prompt = fieldValues.prompt ? "" : "Prompt is required";

        setErrors(tempErrors);

        return Object.values(tempErrors).every(x => x === "");
    };

    const handleInputChange = e => {
        const { name, value } = e.target;
        if (name === 'name') setName(value);
        if (name === 'prompt') setPrompt(value);
        if (name === 'jsxCode') setJsxCode(value);
        validate({ [name]: value });
    };

    const handleBlur = e => {
        const { name, value } = e.target;
        validate({ [name]: value });
        setFormTouched(true);
    };

    const handleCreate = async () => {
        setFormTouched(true);
        setFormSubmitted(true);
        if (validate({ name, prompt, jsxCode })) {
            const data = { name, prompt, jsxCode };
            try {
                const _id = rowId || uuidv4();
                await mongoService.updateDocument(collection, _id, data);
                setName('');
                setPrompt('');
                setJsxCode('');
                setErrors({});
                setFormSubmitted(false);
            } catch (error) {
                console.error(error);
            }
        }
    };

    const uploadChunk = async (file, chunk, chunkNumber, totalChunks, sessionId) => {
        const formData = new FormData();
        formData.append('chunk', chunk);
        formData.append('chunkNumber', chunkNumber);
        formData.append('totalChunks', totalChunks);
        formData.append('filename', file.name);

        const response = await fetch(`${baseURL}/upload_chunk`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('serviceToken')}`,
                'sessionId': sessionId
            },
            body: formData
        });

        if (!response.ok) {
            throw new Error(`Failed to upload chunk ${chunkNumber}`);
        }
    };

    const handleStream = async (prompt) => {
        setFormTouched(true);
        if (validate({ name, prompt })) {
            try {
                // Disconnect the previous socket if it exists
                if (socketRef.current) {
                    socketRef.current.disconnect();
                }

                const sessionId = uuidv4(); // Generate a unique session ID

                // Step 1: Initiate the stream setup with a POST request
                const setupResponse = await fetch(`${baseURL}/setup_stream_chat`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('serviceToken')}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        prompt,
                        componentName: name,
                        componentCode: jsxCode,
                        sessionId: sessionId,
                        prompt_type: 'react'
                    })
                });

                if (!setupResponse.ok) {
                    throw new Error('Failed to set up chat stream');
                }

                // Step 2: Connect to the WebSocket server
                socketRef.current = io(baseURL, {
                    transports: ['websocket'],
                    extraHeaders: {
                        'Authorization': `Bearer ${localStorage.getItem('serviceToken')}`
                    },
                    reconnection: false, // Enable reconnection
                    reconnectionAttempts: 10, // Number of reconnection attempts
                    reconnectionDelay: 1000, //
                });

                socketRef.current.on('connect', () => {
                    console.log('Connected to WebSocket server');
                    setJsxCode('')
                    // Emit the chat_message event once connected
                    socketRef.current.emit('chat_message', {
                        sessionId,
                    });
                });

                let collectedResponse = '';

                socketRef.current.on('chat_response', (data) => {
                    collectedResponse += data.data;
                    setJsxCode((prev) => prev + data.data);
                });

                socketRef.current.on('error', (err) => {
                    console.error('WebSocket error:', err);
                });

                socketRef.current.on('disconnect', () => {
                    console.log('WebSocket connection closed');
                });

                // Disconnect the socket after streaming is finished
                socketRef.current.on('stream_finished', () => {
                    socketRef.current.disconnect();
                    socketRef.current = null; 
                    console.log('Streaming session finished and WebSocket disconnected');
                });

            } catch (error) {
                console.error('Error sending prompt:', error);
            }
        }
    };

    const handlePreview = () => {
        setFormTouched(true);
        const code = jsxCode.match(/```jsx([\s\S]*?)```/)[1];
        setComponentCode(code)
        setComponentName(name);
        setShouldRenderPreview(false); // Force re-render by resetting
        setShowPreview(true);
        hasRenderedPreview.current = false;
        setShouldRenderPreview(true);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={6}>
                <TextField
                    fullWidth
                    id="name"
                    name="name"
                    label="Name"
                    value={name}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                />
                <TextField
                    fullWidth
                    id="prompt"
                    name="prompt"
                    label="Prompt"
                    multiline
                    rows={7}
                    value={prompt}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.prompt)}
                    helperText={errors.prompt}
                    sx={{ mt: 3 }}
                />
                <TextField
                    fullWidth
                    id="jsxCode"
                    name="jsxCode"
                    label="JSX Code"
                    multiline
                    rows={12}
                    value={jsxCode}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    error={Boolean(errors.jsxCode)}
                    helperText={errors.jsxCode}
                    sx={{ mt: 3 }}
                />
                <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }}>
                    <Button
                        variant="contained" sx={{ mr: 2 }}
                        color="primary"
                        onClick={() => handleStream(prompt)}
                    >
                        Generate
                    </Button>
                    <Button variant="contained" sx={{ mr: 2 }} onClick={handlePreview}>
                        Preview
                    </Button>
                    <Button variant="contained" onClick={handleCreate}>
                        Save Schema
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={6}>
                {showPreview && shouldRenderPreview && !hasRenderedPreview.current && (
                    <DynamicLoader
                        componentCode={componentCode}
                    />
                )}
                {showPreview && shouldRenderPreview && (hasRenderedPreview.current = true)}
            </Grid>
            <Grid item xs={12}>
            </Grid>
        </Grid>
    );
};

FormEditor.propTypes = {
    // collection: PropTypes.string.isRequired,
    // rowId: PropTypes.string,
};

export default FormEditor;
