// InputPromptQuery.js
import React, { useState } from "react";
import { TextField, Box, Button } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';

function InputPromptQuery({ prompt, index, handleQueryChange, handleAsk,btnName,label}) {

  console.log(prompt.loading, '[ront input');

  const [loaderBtn , setloaderBtn] = useState(false)
  
  return (
    <Box sx={{ display:'flex', alignItems:'center', width:'100%', gap:'10px'}}>
     <TextField
      fullWidth
      value={prompt.query}
      label={label}
      onChange={(e) => handleQueryChange(index, e.target.value)}
      variant="outlined"
      multiline
      minRows={1}  
      maxRows={1}  
      sx={{
        "& .MuiOutlinedInput-root": {
          alignItems: 'flex-start', 
        },
      }}
    />
      <Box>
        <Button
          variant="contained"
          onClick={() => handleAsk(index)}
          // disabled={prompt.loading || !prompt.query}
          sx={{
            p:1,
            height:'49px',
            minHeight:'49px'
          }}
        >
          { btnName }
        </Button>
      </Box>
    </Box>
  );
}

export default InputPromptQuery;
