
// ==============================|| MENU ITEMS ||============================== //

import application from "./application";

const menuItems = {
    items: [application]
};

export default menuItems;
