import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { Button, Box, Typography, Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete"; // Import the delete icon from MUI
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";


const MultiFileUploader = ({ allowMultiple = true, sessionID}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { appId } = useParams(); // Access the app_id from the URL

  const onDrop = (acceptedFiles) => {
    if (allowMultiple) {
      setSelectedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
      setSuccessMessage("");
      setErrorMessage("");
    } else {
      setSelectedFiles([acceptedFiles[0]]);
      setSuccessMessage("");
      setErrorMessage("");
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: allowMultiple,
  });

  const handleFileClick = () => {
    document.getElementById("custom-file-input").click();
  };

  console.log(appId,"appid in fileuploader");
  console.log(sessionID,"sessionid fileuploader");
  

// const apiFileUrl = `http://127.0.0.1:7005/dms/${appID}/uploadfiles/`;

const apiFileUrl = `${process.env.REACT_APP_FILE_UPLOAD_URL}${appId}/uploadfiles`;

console.log(`API File URL: ${apiFileUrl}`); // Log to check the constructed URL

const handleSubmit = async () => {
  // Check the selected files
  console.log("Selected Files: ", selectedFiles);

  const formData = new FormData();
  selectedFiles.forEach((file) => {
    formData.append("files", file);
  });

  try {
    setSuccessMessage("");
    setErrorMessage("");
    const token = localStorage.getItem('serviceToken');
    console.log(token,"token file uploade");
    

    const response = await fetch(apiFileUrl, {
      method: "POST",
      headers: {
        "Accept": "application/json", // Accept header
        "session-id": sessionID, // Session ID header
        "Authorization": `Bearer ${token}`, // Set the Bearer token
      },
      body: formData,
    });

    if (response.ok) {
      const data = await response.json();
      console.log("Files uploaded successfully:", data);
      setSelectedFiles([]);
      setSuccessMessage("Files uploaded successfully!");
    } else {
      const errorData = await response.json();
      setErrorMessage(errorData.message || "Failed to upload files.");
    }
  } catch (error) {
    setErrorMessage("An error occurred while uploading files.");
  }
};



  // Remove a specific file from the list
  const removeFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div>
      {/* Drag-and-drop area */}
      <Box
        {...getRootProps()}
        sx={{
          border: "2px dashed #ccc",
          borderRadius: "8px",
          p: 2,
          minHeight: "200px",
          height: "auto",
          textAlign: "center",
          cursor: "pointer",
          bgcolor: isDragActive ? "#f0f8ff" : "transparent",
        }}
      >
        <input {...getInputProps()} id="custom-file-input" style={{ display: "none" }} />
        <Typography variant="body1" mt={7}>
          {isDragActive
            ? "Drop the file here ..."
            : "Drag 'n' drop a file here, or click to select file"}
        </Typography>

       
      </Box>
      <Box>
         {/* Selected files as a list */}
         <Typography component="div" style={{ marginTop: "20px" }}>
          <ul>
            {selectedFiles.map((file, index) => (
              <li key={index}>
                {file.name}{" "}
                <IconButton
                  aria-label="delete"
                  onClick={() => removeFile(index)} // Remove file on click
                  size="small"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </li>
            ))}
          </ul>
        </Typography>
      </Box>

      {/* Display success or error message */}
      <Typography
        variant="body2"
        color={successMessage ? "green" : "red"}
        style={{ marginTop: "10px" }}
      >
        {successMessage || errorMessage}
      </Typography>

      {/* Submit button */}
      <Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          style={{ marginTop: 10 }}
        >
          Submit
        </Button>
      </Grid>
    </div>
  );
};

export default MultiFileUploader;
