// material-ui
import { useTheme } from '@mui/material/styles';
import { FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';

// project-imports
import Avatar from 'shared-ui/ui-component/extended/Avatar';

// assets
import { IconMoon, IconSun } from '@tabler/icons';
import { uiStore } from 'shared-ui/store';
import PresetColor from './presetColor';

// ==============================|| CUSTOMIZATION - MODE ||============================== //

const ThemeModeLayout = () => {
    const theme = useTheme();
    const { navType } = uiStore.config;

    return (
        <>
            <Stack direction="row" alignItems="center" p={2} justifyContent="space-between" spacing={2.5} sx={{ width: '100%' }}>
                {/* <Typography variant="h5">THEME </Typography> */}
                <RadioGroup
                    row
                    aria-label="layout"
                    value={navType}
                    onChange={(e) => uiStore.updateConfig('navType', e.target.value)}
                    name="row-radio-buttons-group"
                >
                    <FormControlLabel
                        control={<Radio value="light" sx={{ display: 'none' }} />}
                        label={
                            <Avatar
                                variant="rounded"
                                outline
                                sx={{
                                    width: 48,
                                    height: 48,
                                    ...(theme.palette.mode !== 'light' && { borderColor: theme.palette.divider + 20 })
                                }}
                            >
                                <IconSun color={theme.palette.warning.dark} />
                            </Avatar>
                        }
                    />
                    <FormControlLabel
                        control={<Radio value="dark" sx={{ display: 'none' }} />}
                        label={
                            <Avatar
                                size="md"
                                variant="rounded"
                                color="dark"
                                sx={{
                                    width: 48,
                                    height: 48,
                                    ...(theme.palette.mode === 'dark' && { border: `2px solid ${theme.palette.primary.main}` })
                                }}
                            >
                                <IconMoon style={{ transform: 'rotate(220deg)', color: theme.palette.grey[100] }} />
                            </Avatar>
                        }
                    />
                </RadioGroup>
            </Stack>
            {/* <PresetColor /> */}
        </>
    );
};

export default ThemeModeLayout;
