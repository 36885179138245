import React from "react";
import { useState,useEffect } from "react";
// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Card,
  CardHeader,
  Grid,
  Typography,
  Badge,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";

import Slider from "react-slick";

import card1 from "shared-ui/assets/images/cards/preview (1).webp";
import card2 from "shared-ui/assets/images/cards/Front.png";
import card3 from "shared-ui/assets/images/cards/preview (3).webp";
import card4 from "shared-ui/assets/images/cards/preview (4).webp";
import card5 from "shared-ui/assets/images/cards/Front (4).png";
import card6 from "shared-ui/assets/images/cards/Front (1).png";
import card7 from "shared-ui/assets/images/cards/Front (2).png";
import card8 from "shared-ui/assets/images/cards/Front.png";

import cardIcon from "shared-ui/assets/images/cards/iconCard.svg";

import jsonHome from "service/homedata.json";
import api from "service/api";

const HomeDash = () => {
  const cardImages = [card1, card2, card3, card4,card5,card6,card7,card8]; // Array of card images
  const theme = useTheme();

  const dashboardData = jsonHome

  const settings = {
    // rtl:false,
    autoplay: false,
    arrows: true,
    dots: false,
    infinite: false,
    speed: 700,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 658,
        settings: {
          slidesToShow: 1.25,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2.75,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1640,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },

      
    ],
  };

  const settings2 = {
    autoplay: true,
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await api.getData();
        setData(result);
        console.log(data,"reponsedata");
        
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
        console.log(data,"reponsedata");

      }
    };

    fetchData();
  }, []);

 

  return (
    <Grid
    sx={{
      background: theme.palette.background.bg,
    }}
      m={.5}
    >
        {data?.dashboard.groups.map((group, groupIndex) => (
        <Card className="sliderDivMain" key={groupIndex} sx={{ mb: 2 ,
          background: theme.palette.background.default,
          border:'1px solid',
          borderColor: theme.palette.background.border,
        }}>
          <Typography variant="cardHeader" p={2}>
            {group.heading}
          </Typography>
          <Slider {...settings}>
            {group.apps.map((tile, tileIndex) => (
              <div key={tileIndex} >
                <Link to={`/dashboard/${tile.app_id}`}
                state={{ title: tile.title,
                  endpoint: tile.endpoints.chat
                 }} >
                  <Grid container className="cardApplication">
                    <div className="content" style={{ boxShadow: theme.shadows[10] }}>
                      <Box className="front">
                        <img src={cardImages[tileIndex % cardImages.length]}  alt={`Icon for ${tile.title}`} />
                        <div className="titleDiv">
                          <img src={cardIcon} />
                          <Typography variant="cardText">{tile.title}</Typography>
                        </div>
                      </Box>
                      <Box className="back">
                        <Typography variant="cardTextH" mt={2}>
                          {tile.title}
                        </Typography>
                        <Typography variant="cardDesc" mt={4} mb={2}>
                          {tile.description}
                        </Typography> 
                        <Box display="flex" alignItems="center" flexWrap="wrap">
                          {/* Add any badges or tags here if necessary */}
                          <span className="badge">Data Explporation</span>
                          <span className="badge">Code generation</span>
                          <span className="badge">Charts </span>
                          <span className="badge">Gen AI</span>

                            {/* Render tags dynamically */}
                            {/* {tile.tags.map((tag, tagIndex) => (
                            <span key={tagIndex} className="badge">
                              {tag}
                            </span>
                          ))} */}

                        </Box>
                      </Box>
                    </div>
                  </Grid>
                </Link>
              </div>
            ))}
          </Slider>
        </Card>
      ))}


   
    </Grid>
  );
};

export default HomeDash;
