// material-ui
import { useTheme } from '@mui/material/styles';

// ==============================|| LOGO ||============================== //

import logo from 'shared-ui/assets/images/emstl-logo.png';
import logoDark from 'shared-ui/assets/images/logodark.png';


const Logo = () => {
    const theme = useTheme();

    return <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Zealogics" width="95%" height="auto" />;
};

export default Logo;
